import { gql } from "@apollo/client";
export const GET_INVOICE_QUERY = `
query GetInvoicebyUser($userId: ID!, $page: Int!, $perPage: Int!, $searchQuery: String) {
  getInvoicebyUser(user_id: $userId, page: $page, perPage: $perPage, searchQuery: $searchQuery) {
    totalPages
    totalCount
    invoices {
      id
      user_id
      serial_no
      invoiceid
      invoicedate
      productOrService {
                      name
                      tax
                      amount
                      rate
                      quantity
                    }
      price
      paid_amount
      duedate
      status
      customer_name
      customer_id
      email
      description
      phone_no
      expiry_date
      invoice_no
      gstType
     due_amount
    }
  }
}
`;
export const GET_CUSTOMER_QUERY = `
query GetAddedUsers($page: Int!, $perPage: Int!, $searchQuery: String) {
  getAddedUsers(page: $page, perPage: $perPage, searchQuery: $searchQuery) {
   totalPages
        totalCount
        users {
            _id
            user_id
            user_type
            customer_no
            active_status
            basic_info {
                firstname
                lastname
                email
                phone_number
                customer_type
                company_name
                shipping_address {
                    address_line1
                    city
                    state
                    pincode
                    country
                }
            }
                 billing_info {
                GST_type
                GST_no
                payment_terms
                currency
                business_name
            }
        }
  }
}`;

export const GET_CLIENT_DASHBOARD = `
query GetDashboardCounts($user_id: ID!) {
  getDashboardCounts(user_id: $user_id) {
    totalOrderCount
    totalQuotationCount
    totalInvoiceCount
  }
}`;

export const GET_ALL_CUSTOMERS = `
query GetAllUsers {
  getAllUsers {
           _id
        user_id
        user_type
        customer_no
        basic_info {
                firstname
                lastname
                email
                phone_number
                customer_type
                company_name
  
                shipping_address {
                    address_line1
                    city
                    state
                    pincode
                    country
                }
            }
                 billing_info {
                GST_type
                GST_no
                payment_terms
                currency
                business_name
            }
  }
}`;

export const GET_INVOICE_WITH_TYPE =gql `
query GetInvoicebyUserType($customer_id: ID!, $page: Int!, $perPage: Int!, $searchQuery: String) {
  getInvoicebyUserType(customer_id: $customer_id, page: $page, perPage: $perPage, searchQuery: $searchQuery) {
    totalPages
    totalCount
    id
    message
    invoices {
      id
      serial_no
      user_id
      name
      invoiceid
      quotationid
      quotationNo
      orderid
      order_no
      invoicedate
      price
      paid_amount
      due_amount
      duedate
      status
      paymentdate
      bal_amount
      invoice_no
      customer_id
      phone_no
      email
      currency
      discount
      discountType
      taxamount
      customernotes
      terms_conditions
      order_status
      productOrService {
        name
        hsnCode
        tax
        productplan
        billcycle
        amount
        rate
        quantity
      }
    }
  }
}`;

export const GET_ALL_PRODUCTS = `
query Getallproducts {
  getallproducts {
        name
        hsnCode
        productplan
        billcycle
        tax
        amount
        price
        rate
        quantity
  }
}`;
export const PROFILE_DATA = gql`
  query GetUserById($userId: ID!) {
    getUserById(id: $userId) {
      user_id
      basic_info {
        company_name
        customer_fullname
        contact_number
        email
      }
      contact_info {
        address
        city
        state
        country
        pincode
      }
      billing_info {
        GST_type
        GST_no
        currency
      }
    }
  }
`;
export const CLIENT_PAYMENTS = gql`
  query GetAllPaymentsbyCustomerId($userId: ID!, $page: Int!, $perPage: Int!, $searchQuery: String) {
    getAllPaymentsbyCustomerId(userId: $userId, page: $page, perPage: $perPage, searchQuery: $searchQuery) {
      success
      message
      payments {
        id
        customer_name
        customer_id
        customer_no
        invoice_id
        paymentDue
        paymentMode
        discount
        discountType
        customernotes
        paidAmount
        creditAmount
        status
        dueAmount
        duedate
        invoicedate
        currency
        productOrService {
          name
          hsnCode
          productplan
          billcycle
          tax
          amount
          price
          rate
          quantity
          paid
        }
      }
      pagination {
        total
        page
        perPage
        totalPages
      }
    }
  }
`;
