import React, { useState } from 'react';
import { Grid, TextField, Typography, Divider, Button } from '@mui/material';
import PheonixCard from '../Components/PheonixCard';
import PheonixTextField from '../Components/PheonixTextField';
import { TEXT_MESSAGES } from '../const';
import PheonixButton from '../Components/PheonixButton';
const Newcustomer: React.FC = () => {
  const [error, setError] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [card1Title, setCard1Title] = useState(TEXT_MESSAGES.BASIC_INFO);
  const [card1ButtonText, setCard1ButtonText] = useState(TEXT_MESSAGES.BUTTON_TXT);
  const [card1ButtonDisabled, setCard1ButtonDisabled] = useState(false);
  const [card2Title, setCard2Title] = useState(TEXT_MESSAGES.CONTACT_INFO);
  const [card2ButtonText, setCard2ButtonText] = useState(TEXT_MESSAGES.ANOTHER_CONTACT);
  const [card2ButtonDisabled, setCard2ButtonDisabled] = useState(false);
  const [customerType, setCustomerType] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [websiteURL, setWebsiteURL] = useState('');
  const [fullName, setFullName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [shippingAddress, setShippingAddress] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [country, setCountry] = useState('');
  const [password,setPassword]=useState('');
  const [gst_type,setGsttype]=useState('');
  const[gstin,setGstin]=useState('');
  const[legal,setLegal]=useState('');
  const[payment_terms,setPaymentterms]=useState('');
  const[currency,setCurrency]=useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [contactname, setContactname] = useState('');
  const[internal_use,setInternaluse]=useState('');
  const [card3Title, setCard3Title] = useState(TEXT_MESSAGES.BILLING_INFO);
  const [card4Title, setCard4Title] = useState(TEXT_MESSAGES.CLIENT_PORTAL);
  const [card5Title, setCard5Title] = useState(TEXT_MESSAGES.REMARKS);
  const [card3ButtonText, setCard3ButtonText] = useState(TEXT_MESSAGES.BUTTON_TXT);
  const [card3ButtonDisabled, setCard3ButtonDisabled] = useState(false);
  const [additionalContactFields, setAdditionalContactFields] = useState(0);
  const handleCard1ButtonClick = () => {
  };
  const handleCard2ButtonClick = () => {
  };
  const handleCard3ButtonClick = () => {
  };
  const handleAddContactClick = () => {
    setAdditionalContactFields(prevCount => prevCount + 1);
  };
  const handleButton = ()=>{

  }
  const handleCancel= ()=>{

  }
  const isMobileView = window.innerWidth <= 768;
  return (
    <div style={{ backgroundColor: '#f1f1f1', overflow: 'hidden', minHeight: '84vh', padding: isMobileView ? "0" : "40px", maxWidth: isSidebarOpen ? 'calc(100% - 70px)' : 'calc(100% - 80px)' }}>
         <PheonixCard
          buttonText1={TEXT_MESSAGES.SAVE}
          buttonText2={TEXT_MESSAGES.CANCEL}
          buttonDisabled1={card2ButtonDisabled}
          onClickButton={handleAddContactClick}
          handleCancelbutton={handleCancel}
      isMobileView={false}
      >
      <PheonixCard
        title={card1Title}
        onClickButton={handleCard1ButtonClick}
        isMobileView={false}
      >
        <Grid container spacing={2} sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
          padding: "15px", marginLeft: "0px"
        }}>
          <Grid item xs={12} sm={3} >
            <PheonixTextField label="Customer Type"
              type="text"
              value={customerType}
              placeholder="Customer Type"
              onChange={e => setCustomerType(e.target.value)}
              variant="outlined" 
            />
            <PheonixTextField label="Company Name" type="text"
              value={companyName}
              onChange={e => setCompanyName(e.target.value)}
              placeholder="Company Name" />
            <PheonixTextField label="Website URL" type="text"
              value={websiteURL}
              onChange={e => setWebsiteURL(e.target.value)}
              placeholder="Website URL" />
          </Grid>

          <Grid item xs={12} sm={3}>
            <PheonixTextField label="Full Name" type="text"
              value={fullName}
              onChange={e => setFullName(e.target.value)}
              placeholder="Full Name" />
            <PheonixTextField label="Last Name" type="text"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              placeholder="Last Name"  />
          </Grid>
          <Grid item xs={12} sm={3}>
            <PheonixTextField label="Email" type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email"  />
            <PheonixTextField label="Phone Number" type="text"
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              placeholder="Phone Number" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <PheonixTextField label="Shipping Address" type="text"
              value={shippingAddress}
              onChange={e => setShippingAddress(e.target.value)}
              placeholder="Shipping Address" />
            <PheonixTextField label="Address Line2" type="text"
              value={addressLine2}
              onChange={e => setAddressLine2(e.target.value)}
              placeholder="Address Line2"  />
            <PheonixTextField label="City" type="text"
              value={city}
              onChange={e => setCity(e.target.value)}
              placeholder="City"  />
            <PheonixTextField label="State" type="text"
              value={state}
              onChange={e => setCity(e.target.value)}
              placeholder="State"  />
            <PheonixTextField label="Zipcode" type="text"
              value={zipcode}
              onChange={e => setZipcode(e.target.value)}
              placeholder="Zipcode" />
            <PheonixTextField label="Country" type="text"
              value={country}
              onChange={e => setCountry(e.target.value)}
              placeholder="Country"  />
          </Grid>
        </Grid>
      </PheonixCard>
      <PheonixCard
        title={card2Title}
        buttonText={card2ButtonText}
        buttonDisabled={card2ButtonDisabled}
        onClickButton={handleAddContactClick}
        isMobileView={false}

        button
      >
        <Grid container spacing={2} sx={{
          '& .MuiTextField-root': { m: 1, width: '30ch' },
          textAlign: "center",
          justifyContent: "space-between"
        }}>
          <Grid item xs={12} sm={4}>
            <PheonixTextField label="Contact Name" type="text"
              value={contactname}
              onChange={e => setContactname(e.target.value)}
              placeholder="Contact Name"  />
          </Grid>
          <Grid item xs={12} sm={4}>

            <PheonixTextField label="Phone Number" type="text"
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              placeholder="Phone Number" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PheonixTextField label="Email" type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email"  />
          </Grid>
        </Grid>
        {[...Array(additionalContactFields)].map((_, index) => (
          <React.Fragment key={index}>
            <Grid container spacing={2} sx={{
              '& .MuiTextField-root': { m: 1, width: '30ch' },
              textAlign: "center",
              justifyContent: "space-between"
            }}>
              <Grid item xs={12} sm={4}>
                <PheonixTextField label="Contact Name" type="text"
                  value={contactname}
                  onChange={e => setContactname(e.target.value)}
                  placeholder="Contact Name"  />
              </Grid>
              <Grid item xs={12} sm={4}>

                <PheonixTextField label="Phone Number" type="text"
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                  placeholder="Phone Number"  />
              </Grid>
              <Grid item xs={12} sm={4}>
                <PheonixTextField label="Email" type="text"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Email"  />
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </PheonixCard>
      <PheonixCard
        title={card3Title}
        onClickButton={handleCard3ButtonClick}
        isMobileView={false}
      >
        <Grid container spacing={2} sx={{
          '& .MuiTextField-root': { m: 1, width: '35ch' },
          padding: "15px", marginLeft: "0px"
        }}>
          <Grid item xs={12} sm={4} >
            <PheonixTextField label="GST Type"
              type="text"
              value={gst_type}
              placeholder="GST Type"
              onChange={e => setGsttype(e.target.value)}
              variant="outlined"  />
            <PheonixTextField label="GSTIN" type="text"
              value={gstin}
              onChange={e => setGstin(e.target.value)}
              placeholder="GSTIN"  />
            <PheonixTextField label="Legal Business Name" type="text"
              value={legal}
              onChange={e => setLegal(e.target.value)}
              placeholder="Legal Business Name"  />
          </Grid>

          <Grid item xs={12} sm={4}>
            <PheonixTextField label="Payment Terms" type="text"
              value={payment_terms}
              onChange={e => setPaymentterms(e.target.value)}
              placeholder="Payment Terms"  />
            <PheonixTextField label="Currency - INR" type="text"
              value={currency}
              onChange={e => setCurrency(e.target.value)}
              placeholder="Currency - INR" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PheonixTextField label="Billing Address" type="text"
              value={billingAddress}
              onChange={e => setBillingAddress(e.target.value)}
              placeholder="Billing Address"  />
            <PheonixTextField label="Address Line2" type="text"
              value={addressLine2}
              onChange={e => setAddressLine2(e.target.value)}
              placeholder="Address Line2"  />
            <PheonixTextField label="City" type="text"
              value={city}
              onChange={e => setCity(e.target.value)}
              placeholder="City"  />
            <PheonixTextField label="State" type="text"
              value={state}
              onChange={e => setCity(e.target.value)}
              placeholder="State" />
            <PheonixTextField label="Zipcode" type="text"
              value={zipcode}
              onChange={e => setZipcode(e.target.value)}
              placeholder="Zipcode"  />
            <PheonixTextField label="Country" type="text"
              value={country}
              onChange={e => setCountry(e.target.value)}
              placeholder="Country"  />
          </Grid>
        </Grid>
      </PheonixCard>
      <PheonixCard
        title={card4Title}
     onClickButton={handleAddContactClick}
        isMobileView={false}

        button
      >
        <Grid container spacing={2} sx={{
          '& .MuiTextField-root': { m: 1, width: '30ch' },
          textAlign: "center",
          justifyContent: "space-between"
        }}>
          <Grid item xs={12} sm={4}>
            <PheonixTextField label="Email" type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email"  />
          </Grid>
          <Grid item xs={12} sm={4}>

            <PheonixTextField label="Password" type="text"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Password" />
          </Grid>
          <Grid item xs={12} sm={4}>
          <Button style={{marginTop:"20px"}}>SEND INVITE</Button>
          </Grid>
        </Grid>
     
      </PheonixCard>
      <PheonixCard
        title={card5Title}
        onClickButton={handleAddContactClick}
        isMobileView={false}

        button
      >
        <Grid container spacing={2} sx={{
          '& .MuiTextField-root': { m: 1, width: '125ch' },
          textAlign: "center",
          justifyContent: "space-between"
        }}>
          <Grid item xs={12} sm={4}>
            <PheonixTextField label="For Internal Use" type="text"
              value={internal_use}
              onChange={e => setInternaluse(e.target.value)}
              placeholder="For Internal Use"  />
          </Grid>
       
        </Grid>
     
      </PheonixCard>
      
      </PheonixCard>
    </div>
  );
};
export default Newcustomer;