import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography, Box, Button, IconButton } from "@mui/material";
import { TEXT_MESSAGES } from "../../const";
import arrow from "../../assets/arow.png";
import PheonixBreadcrumbs from "../../Components/PheonixBreadcrumbs";
import { GET_ALL_CUSTOMERS } from "../../graphql/query";
import SnackBar from "../../Components/PheonixSnackBar";
import { api } from "../../api";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import QuoteData from "./PheonixQuotation";
import QuoteReqtable from "./QuotesReqtable";
import { RowData } from "./PheonixProductTableprops";

const initialQuoteData: QuoteData = {
  "Quotation Date": "",
  "Customer Notes": "",
};
interface CustomerOption {
  id: string;
  name?: string;
  email: string;
  number?: string;
  gst_no?: string;
  address?: string;
  customerNo: string;
  city?: string;
  state?: string;
  pincode?: string;
  country?: string;
}
interface ProductOrService {
  name?: string | null;
  quantity: Number | null;
  productplan: string | null;
}
const customer: CustomerOption = {
  id: "",
  name: "",
  email: "",
  number: "",
  gst_no: "",
  address: "",
  customerNo: "",
  city: "",
  state: "",
  pincode: "",
  country: "",
};
const QuotationRequest: React.FC = () => {
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const [quotationDate, setQuotationDate] = useState<Date | null>(null);
  const [status, setStatus] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [convert, setconvert] = useState(false);
  const [succMessage, setSuccMessage] = useState("");
  const [quotesData, setQuotesData] = useState<QuoteData>(initialQuoteData);
  const [fieldErrors, setFieldErrors] = useState<QuoteData>(initialQuoteData);
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([
    customer,
  ]);
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [error, setError] = useState("");
  const [newBreadcrumbText, setNewBreadcrumbText] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const editQuote = location.state?.editQuote || null;
  const view = location.state?.view;
  const breadcrumbText = location.state?.breadcrumbText || "";
  const [rows, setRows] = useState<RowData[]>([
    {
      "Product Details": "",
      Quantity: 0,
      "Product Plan": "",
    },
  ]);
  type FieldErrors = {
    [index: string]: {
      [fieldName: string]: string;
    };
  };
  const [tableFieldErrors, setTableFieldErrors] = useState<FieldErrors>({});

  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [buttonDisable, setButtonDisable] = useState(true);
  const [rowDisable, setRowDisable] = useState(true);
  const [tableDisable, setTableDisable] = useState(false);
  const [confirmDisable, setConfirmDisable] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  const areAllFieldsHasValues = (data: QuoteData) => {
    const fieldsToExclude = ["Quotation Number", "Discount Amount","Customer Notes"];

    return Object.entries(data).every(([key, value]) => {
      return fieldsToExclude.includes(key) || value !== "";
    });
  };
  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: GET_ALL_CUSTOMERS,
        },
        config
      );
      const customers = response.data.data.getAllUsers;
      const customersClient = customers.filter(
        (customer: any) => customer.user_type === "2"
      );
      if (customers) {
        const customerOptions = customersClient.map(
          (customer: {
            _id: string;
            customer_no: string;
            basic_info: {
              firstname: string;
              email: string;
              phone_number: string;
              shipping_address: {
                address_line1: string;
                city: string;
                state: string;
                pincode: string;
                country: string;
              };
            };
            billing_info: { GST_no: string };
          }) => ({
            id: customer._id,
            name: customer.basic_info.firstname,
            email: customer.basic_info.email,
            number: customer.basic_info.phone_number,
            gst_no: customer.billing_info.GST_no,
            address: customer.basic_info.shipping_address.address_line1,
            customerNo: customer.customer_no,
            city: customer.basic_info.shipping_address.city,
            state: customer.basic_info.shipping_address.state,
            pincode: customer.basic_info.shipping_address.pincode,
            country: customer.basic_info.shipping_address.country,
          })
        );
        setCustomerOptions(customerOptions);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  const checkForErrors = (): boolean => {
    for (const rowIndex in tableFieldErrors) {
      for (const field in tableFieldErrors[rowIndex]) {
        if (tableFieldErrors[rowIndex][field]) {
          return true;
        }
      }
    }
    return false;
  };

  const handleBack = () => {
    navigate("/quotation");
  };
  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => !!error);
    setConfirmDisable(hasErrors);
  }, [fieldErrors]);

  useEffect(() => {
    const hasTableErrors = checkForErrors();
    setTableDisable(hasTableErrors);
  }, [tableFieldErrors]);

  useEffect(() => {
    const isButtonDisabled = areAllFieldsHasValues(quotesData);

    if (isButtonDisabled) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [quotesData]);

  const areAllRowsComplete = (rows: RowData[]): boolean => {
    return rows.every((row) => {
      return Object.entries(row).every(([key, value]) => {
        return value !== "" && (key !== "Quantity" || value !== 0);
      });
    });
  };
  useEffect(() => {
    const isAllRows = areAllRowsComplete(rows);
    if (isAllRows) {
      setRowDisable(false);
    } else {
      setRowDisable(true);
    }
  }, [rows]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const mapRowsToProductOrService = (rows: RowData[]): ProductOrService[] => {
    return rows.map((row) => ({
      name: row["Product Details"] || null,
      quantity: row["Quantity"] ? Number(row["Quantity"]) : null,
      productplan: row["Product Plan"] || null,
    }));
  };

  const customCloseIcon = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
      style={{ justifyContent: "end" }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  const addDays = (dateStr: any, days: any) => {
    const [day, month, year] = dateStr.split("/").map(Number);
    const date = new Date(year, month - 1, day);
    date.setDate(date.getDate() + days);
    const dueDate = `${("0" + date.getDate()).slice(-2)}/${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}/${date.getFullYear()}`;
    return dueDate;
  };

  const handleSave = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const customerdata = customerOptions.find((user) => user.id === userId);
    const productOrService = mapRowsToProductOrService(rows);


    const query = `
    mutation AddQuotation($user_id: ID!
      $name: String
      $quotationdate: String
      $duedate: String
      $productOrService: [ProductOrServiceInput]
      $customernotes: String
      $customerName: String
      $customerId:String!
      $customerNo: String
      $phoneNumber: String
      $email: String
      $address: String
      $status: String! ){
      addQuotation(user_id: $user_id
        name: $name
        quotationdate: $quotationdate
        duedate: $duedate
        productOrService: $productOrService
        customernotes: $customernotes
        customerName: $customerName
        customerNo: $customerNo
        customerId: $customerId
        phoneNumber:$phoneNumber
        email:$email
        address:$address
        status: $status){
          id
          serial_no
          user_id
          name
          quotationid
          quotationdate
          productOrService {
        name
        tax
        amount
        rate
        quantity
      }
          duedate
          customernotes
          quotationnotes
          currency
          customerName
          customerNo
          customerId
          phoneNumber
          email
          address
          terms_conditions
          price
          status
      }
    }
    `;

    const variables = {
      user_id: userId,
      name: customerdata?.name,
      quotationdate: quotesData["Quotation Date"],
      duedate: addDays(quotesData["Quotation Date"], 30),
      productOrService: productOrService,
      customernotes: quotesData["Customer Notes"],
      customerName: customerdata?.name,
      customerId: userId,
      customerNo: customerdata?.customerNo,
      email: customerdata?.email,
      phonenumber: customerdata?.number,
      address: customerdata?.address,
      status: "Pending",
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        config
      );
      const { addQuotation } = response.data.data;

      if (addQuotation && addQuotation.id) {
        setSnackbarSeverity("success");
        setSuccMessage(
          `${addQuotation.quotationid} - Quotation ${TEXT_MESSAGES.QUOTATION_SUCCESS}`
        );
      } else {
        setSuccMessage(TEXT_MESSAGES.Quotation_FAILURE);
        setSnackbarSeverity("error");
      }

      setModalOpen(true);
      setTimeout(() => {
        setModalOpen(false);
        navigate("/quotation");
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setQuotesData({
      "Quotation Date": "",
      "Customer Notes": "",
    });
    navigate("/quotation");
  };

  return (
    <Box
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "1%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            marginLeft: "1%",
          }}
        >
          <Typography
            style={{
              fontSize: "18px",
              fontFamily: "Roboto",
              color: "#181D8C",
              fontWeight: 600,
              textAlign: "center",
              lineHeight: "21px",
            }}
          >
            {TEXT_MESSAGES.QUOTATION}
          </Typography>
          <PheonixBreadcrumbs
            breadcrumbText={newBreadcrumbText || breadcrumbText}
          />
        </div>

        <div style={{ display: "flex", height: "35px" }}>
          <Button
            onClick={handleBack}
            sx={{
              color: "#181D8C",
              border: "1px solid",
              backgroundColor: "white",
              width: "208px",
              borderRadius: "5px",
              marginRight: "20px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            <img
              src={arrow}
              style={{
                marginRight: "10px",
                marginLeft: 0,
                height: "16px",
                width: "18px",
              }}
            />
            {TEXT_MESSAGES.BACK_QUOTE}
          </Button>
        </div>
      </Box>
      <div
        style={{
          display: "flex",

          flexDirection: "column",
          marginTop: "15px",
          border: "0.5px solid #B7BAFF",
          padding: "5px",
          width: "97%",
          marginLeft: "1%",
          borderRadius: "4px",
          height: "70vh",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1%",
            flexDirection: "column",
            justifyContent: "space-around",
            margin: "5px",
            padding: "5px",
            marginTop: "0px",
            marginLeft: "-5px",
            marginRight: "0%",
            width: "95%",
          }}
        >
          <QuoteReqtable
            QuotesData={quotesData}
            setQuotesData={setQuotesData}
            status={status}
            setStatus={setStatus}
            currency={currency}
            setCurrency={setCurrency}
            QuotationDate={quotationDate}
            setQuotationDate={setQuotationDate}
            ExpiryDate={expiryDate}
            setExpiryDate={setExpiryDate}
            fieldErrors={fieldErrors}
            setFieldErrors={setFieldErrors}
            customerOptions={customerOptions}
            setCustomerOptions={setCustomerOptions}
            rows={rows}
            setRows={setRows}
            tableFieldErrors={tableFieldErrors}
            setTableFieldErrors={setTableFieldErrors}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              alignSelf: "flex-end",
              marginTop: "10px",
              flexDirection: "column",
              marginLeft: "10%",
            }}
          >
            <div
              style={{
                height: "0.5px",
                width: "110%",
                backgroundColor: "#CBC2E9",
              }}
            ></div>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <Button
                onClick={handleCancel}
                style={{
                  color: "#181d8C",
                  backgroundColor: "white",
                  border: "1px solid #181D8C",
                  width: "125px",
                  height: "36px",
                  borderRadius: "5px",
                  marginLeft: "2%",
                }}
              >
                {TEXT_MESSAGES.CANCEL}
              </Button>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "flex-end",
                }}
              >
                <Button
                  onClick={handleSave}
                  disabled={buttonDisable || confirmDisable || rowDisable}
                  sx={{
                    color: "white",
                    border: "1px solid",
                    backgroundColor:
                      buttonDisable || confirmDisable || rowDisable
                        ? "lightgray"
                        : "#181D8C",
                    width: "125px",
                    height: "36px",
                    borderRadius: "5px",
                    marginRight: "2%",
                    "&:hover": { backgroundColor: "#181D8C" },
                    "&:active": { backgroundColor: "#181D8C" },
                  }}
                >
                  {TEXT_MESSAGES.SAVE}
                </Button>
              </div>
            </Box>
          </div>
        </Box>
      </div>

      <SnackBar
        open={modalOpen}
        onClose={handleClose}
        message={succMessage}
        vertical="top"
        horizontal="right"
        customAction={customCloseIcon}
        severity={snackbarSeverity}
      />
    </Box>
  );
};
export default QuotationRequest;
