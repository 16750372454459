import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../Components/PheonixDeleteModal";
import { useNavigate } from "react-router-dom";
import { api } from "../../api";
import PheonixSnackBar from "../../Components/PheonixSnackBar";
import axios from "axios";
import Invoicemock from "../../interfaces/Invoice";
import PheonixPaper from "../../Components/PheonixPaper";
import Popover from "@mui/material/Popover";
import PheonixButton from "../../Components/PheonixButton";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../assets/logo.jpg";
import { Typography, Box, Button, IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSignUpContext } from "../../Contexts/SignUpContext";
import PheonixDataGrid from "../../Components/PheonixDataGrid";
import PheonixChip from "../../Components/PheonixChip";
import PheonixModal from "../Quotation/PheonixModal";
import { TEXT_MESSAGES } from "../../const";
import { useQuery } from "@apollo/client";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import PheonixBreadcrumbs from "../../Components/PheonixBreadcrumbs";
import { pdfdata } from "../../const";
import { GET_ALL_CUSTOMERS } from "../../graphql/query";
import { GET_INVOICE_QUERY, GET_INVOICE_WITH_TYPE } from "../../graphql/query";
import { DELETE_INVOICE } from "../../graphql/mutation";
import { ProductOrService, RowData, CustomerOption } from "./InvoiceInterface";
import InvoiceModal from "./InvoiceModal";

const customer: CustomerOption = {
  id: "",
  name: "",
  email: "",
  number: "",
  gst_no: "",
  address: "",
  customerNo: "",
  city: "",
  state: "",
  pincode: "",
  country: "",
  currency: "",
};

const Invoicescreen: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [invoicessData, setInvoicesData] = useState<Invoicemock[]>([]);
  const [viewData, setViewData] = useState<RowData[]>([]);
  const [filteredInvoicesData, setFilteredInvoicesData] = useState<
    Invoicemock[]
  >([]);
  const [error, setError] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);
  const [entityToDisplay, setEntityToDisplay] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [succMessage, setSuccMessage] = useState("");
  const [dataLoading, setdataLoading] = useState(false);
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([
    customer,
  ]);
  const [customerData, setCutomerData] = useState<CustomerOption>();
  const userId = localStorage.getItem("userId");
  const [open, setOpen] = useState(false);

  const {
    loading: invoiceLoading,
    error: invoiceError,
    data: invoiceData,
    refetch: invoiceRefetch,
  } = useQuery(GET_INVOICE_WITH_TYPE, {
    variables: {
      customer_id: userId,
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery,
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, invoices } = data.getInvoicebyUserType;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;

      const responseDataWithIndex = invoices.map((item: {}, index: number) => ({
        ...item,
        serial_no: (startIndex + index).toString(),
      }));
      setdataLoading(false);

      setInvoicesData(responseDataWithIndex);
      setTotalPages(totalCount);
    },
  });

  useEffect(() => {
    setdataLoading(true);
    if (!invoiceLoading && !invoiceError && invoiceData) {
      const { totalPages, totalCount, invoices } =
        invoiceData.getInvoicebyUserType;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const responseDataWithIndex = invoices.map((item: {}, index: number) => ({
        ...item,
        serial_no: (startIndex + index).toString(),
      }));

      setInvoicesData(responseDataWithIndex);
      setTotalPages(totalCount);

      setdataLoading(false);
    }
  }, [
    invoiceData,
    invoiceLoading,
    invoiceError,
    paginationModel.page,
    paginationModel.pageSize,
    searchQuery,
  ]);

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      invoiceRefetch({ variables: { searchQuery: {} } });
    } else {
      invoiceRefetch();
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const handleDeleteClick = (id: string, name: string) => {
    setEntityToDelete(id);
    setEntityToDisplay(name);
    setDeleteModalOpen(true);
  };
  const confirmDelete = async () => {
    if (!entityToDelete) return;
    const token = localStorage.getItem("token");
    const query = DELETE_INVOICE;
    const variables = {
      id: entityToDelete,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        config
      );
      const { success, message } = response.data.data.deleteInvoice;
      if (success) {
        setSuccMessage(TEXT_MESSAGES.INVOICE_DELETE_SUCCESS);
        setDeleteModalOpen(false);
      } else {
        setSuccMessage(TEXT_MESSAGES.INVOICE_DELETE_FAIL);
      }
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
        invoiceRefetch();
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setdataLoading(true);
    invoiceRefetch();
  }, []);

  const fetchCustomers = async () => {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: GET_ALL_CUSTOMERS,
        },
        config
      );
      const customers = response.data.data.getAllUsers;
      const customersClient = customers.filter(
        (customer: any) => customer.user_type === "2"
      );
      if (customers) {
        const customerOptions = customersClient.map(
          (customer: {
            _id: string;
            customer_no: string;
            basic_info: {
              firstname: string;
              email: string;
              phone_number: string;
              shipping_address: {
                address_line1: string;
                city: string;
                state: string;
                pincode: string;
                country: string;
              };
            };
            billing_info: { GST_no: string; currency: string };
          }) => ({
            id: customer._id,
            name: customer.basic_info.firstname,
            email: customer.basic_info.email,
            number: customer.basic_info.phone_number,
            gst_no: customer.billing_info.GST_no,
            address: customer.basic_info.shipping_address.address_line1,
            customerNo: customer.customer_no,
            city: customer.basic_info.shipping_address.city,
            state: customer.basic_info.shipping_address.state,
            pincode: customer.basic_info.shipping_address.pincode,
            country: customer.basic_info.shipping_address.country,
            currency: customer.billing_info.currency,
          })
        );
        setCustomerOptions(customerOptions);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  const getStatusColorAndIcon = (status: string) => {
    switch (status.toLowerCase()) {
      case "paid":
        return { color: "#05CD31", Icon: CheckCircleIcon };
      case "unpaid":
        return { color: "#9F9F9F", Icon: CancelIcon };
      case "partially paid":
        return { color: "#FC891F", Icon: CheckCircleIcon };
      case "cancelled":
        return { color: "#FD3232", Icon: CancelIcon };
      case "overdue":
        return { color: "#CD0505", Icon: ErrorOutlineOutlinedIcon };
      default:
        return { color: "#000000", Icon: CheckCircleIcon };
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleOpen = () => {
    navigate("/invoicenew");
  };

  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };
  const imageUrl = logo;

  const mapRowsToProductOrService = (rows: RowData[]): ProductOrService[] => {
    return rows.map((row) => ({
      name: row.name || null,
      tax: row.tax || null,
      amount: row.amount ? Number(row.amount) : null,
      rate: row.rate ? Number(row.rate) : null,
      quantity: row.quantity ? Number(row.quantity) : null,
      productplan: row.productplan || null,
      billcycle: row.billcycle || null,
      hsnCode: row.hsncode || null,
    }));
  };

  const getCurrencySymbol1 = (currency: any) => {
    switch (currency) {
      case "Rupees":
        return "₹";
      case "Dollars":
        return "$";
      case "Euro":
        return "€";
      default:
        return "₹";
    }
  };

  const getCurrencySymbol = (currency: any) => {
    switch (currency) {
      case "Rupees":
        return "Rs.";
      case "Dollars":
        return "$";
      case "Euro":
        return "€";
      default:
        return "₹";
    }
  };

 
  const handleDownloadPDF = (rowData: any) => {

    const productOrService = mapRowsToProductOrService(rowData.productOrService);
    const doc = new jsPDF() as any;
    doc.addImage(imageUrl, "JPEG", 15, 10, 50, 35);
    doc.setFontSize(40);
    doc.setFont("helvetica", "bold");
    const text = rowData.currency === "Rupees" ? "Invoice" : "Export Invoice";
    const xPosition = text === "Invoice" ? 145 : 98; 
    doc.text(text, xPosition, 20);
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const pageWidth = doc.internal.pageSize.getWidth();
    const marginRight = 15;
    const labelMarginLeft = 14;

    const rightAlignTextWithColors = (
      label: any,
      value: any,
      labelColor: any,
      valueColor: any,
      yPosition: any,
      applyLabelMarginLeft: any
    ) => {
      const labelWidth =
        (doc.getStringUnitWidth(label) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const valueWidth =
        (doc.getStringUnitWidth(value) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const totalWidth = labelWidth + valueWidth;

      const xLabel =
        pageWidth -
        totalWidth -
        marginRight -
        (applyLabelMarginLeft ? labelMarginLeft : 0);
      const xValue =
        xLabel + labelWidth + (applyLabelMarginLeft ? labelMarginLeft : 0);

      doc.setTextColor(labelColor);
      doc.text(label, xLabel, yPosition);

      doc.setTextColor(valueColor);
      doc.text(value, xValue, yPosition);
    };
    const safeText = (text: any) => (text ? text.toString() : "");

    const texts = [
      {
        label: "Invoice ID : ",
        value: rowData.invoiceid,
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: false,
      },
      {
        label: "Invoice Date : ",
        value: rowData.invoicedate,
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: false,
      },
      {
        label: "Valid Till : ",
        value: rowData.duedate,
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: false,
      },
    ];

    const yPositions = [28, 35, 42];
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");

    texts.forEach((text, index) => {
      rightAlignTextWithColors(
        text.label,
        text.value,
        text.labelColor,
        text.valueColor,
        yPositions[index],
        text.applyLabelMarginLeft
      );
    });

    doc.setTextColor("#808080");
    doc.text("From ", 15, 60);
    doc.setTextColor("#000000");
    const pheonixAddress = pdfdata.PHEONIX_ADDRESS;
    doc.text(pheonixAddress, 12, 65);

    const text1 = "To ";

    const customerDetails = customerOptions.find(
      (customer) => customer.id === rowData.customer_id
    );


    const text2 = safeText(customerDetails?.name);
    const text3 = safeText(customerDetails?.email);
    const text4 = safeText(customerDetails?.number);
    const text5 = safeText(customerDetails?.address);
    const text6 = safeText(customerDetails?.city);
    const text7 = safeText(customerDetails?.state);
    const text8 = safeText(customerDetails?.pincode);
    const text9 = safeText(customerDetails?.gst_no);
    const minX = 120;
    const calculateTextWidth = (text:any) =>
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    const x1 = Math.max(
      pageWidth - calculateTextWidth(text1) - marginRight,
      minX
    );
    const x2 = Math.max(
      pageWidth - calculateTextWidth(text2) - marginRight,
      minX
    );
    const x3 = Math.max(
      pageWidth - calculateTextWidth(text3) - marginRight,
      minX
    );
    const x4 = Math.max(
      pageWidth - calculateTextWidth(text4) - marginRight,
      minX
    );
    const x6 = Math.max(
      pageWidth - calculateTextWidth(text6) - marginRight,
      minX
    );
    const x7 = Math.max(
      pageWidth - calculateTextWidth(text7) - marginRight,
      minX
    );
    const x8 = Math.max(
      pageWidth - calculateTextWidth(text8) - marginRight,
      minX
    );
    const x9 = Math.max(
      pageWidth - calculateTextWidth(text9) - marginRight,
      minX
    );

    let yPosition = 60;
    doc.setTextColor("#808080");
    doc.text(text1, x1, yPosition);

    doc.setTextColor("#000000");
    doc.text(text2, x2, (yPosition += 10));
    doc.text(text3, x3, (yPosition += 5));
    doc.text(text4, x4, (yPosition += 5));

    const addressLines = text5.split("\n");
    addressLines.forEach((line:any) => {
   
      const wrappedLine = doc.splitTextToSize(line, pageWidth - minX - marginRight);
      wrappedLine.forEach((linePart:any) => {
        doc.text(linePart, pageWidth - marginRight, (yPosition += 5), { align: "right" });
      });
    });

    yPosition += 10;

    doc.text(text6, x6, yPosition);
    doc.text(text7, x7, (yPosition += 5));
    doc.text(text8, x8, (yPosition += 5));
    doc.text(text9, x9, (yPosition += 5));
    const products = productOrService.map((service: any) => ({
      name: service.name,
      amount: service.amount,
      productplan: service.productplan,
      tax: service.tax,
      quantity: service.quantity
    }));
    const subtotal = products.reduce((sum, product) => sum + parseFloat(product.amount), 0);
    let cgst = 0;
    let sgst = 0;
    let igst = 0;
    
    const currencydata = rowData.currency;
    const gstNumber = customerDetails?.gst_no || "";
    
    if (currencydata === "Rupees") {
      if (!gstNumber) {
        products.forEach((product) => {
          const productAmount = parseFloat(product.amount);
          cgst += productAmount * 0.09;
          sgst += productAmount * 0.09;
        });
      } else if (gstNumber.startsWith("33")) {
        products.forEach((product) => {
          const productAmount = parseFloat(product.amount);
          cgst += productAmount * 0.09;
          sgst += productAmount * 0.09;
        });
      } else {
        products.forEach((product) => {
          const productAmount = parseFloat(product.amount);
          igst += productAmount * 0.18;
        });
      }
    }
    const currencysymbl = getCurrencySymbol(currencydata);

    const bodyData = products.map((name) => [
      name.name,
      name.productplan,
      name.quantity,
      `${currencysymbl} ${name.amount}`,
    ]);
    
    let startY = 130;
    const customStyles = {
      fillColor: [255, 255, 255],
      lineHeight: 50,
      cellPadding: 2,
      textColor: [0, 0, 0],
      fontSize: 12,
    };
    doc.autoTable({
      startY,
      head: [["Product/Service", "Product Plan", "Quantity", "Price"]],
      body: bodyData,
      styles: customStyles,
      didParseCell: function (table:any) {
        if (table.section === "head") {
          table.cell.styles.textColor = "#000000";
          table.cell.styles.fontSize = 12;
        }
      },
    });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    const subtotalText = `Subtotal: ${currencysymbl} ${subtotal.toFixed(2)}`;
     yPosition = doc.autoTable.previous.finalY + 10;


    doc.text(subtotalText, 150, yPosition);
    yPosition += 10; 
    
  
    if (cgst > 0 || sgst > 0) {
      doc.text(`CGST 9%: ${currencysymbl} ${cgst.toFixed(2)}`, 150, yPosition);
      yPosition += 10;
      doc.text(`SGST 9%: ${currencysymbl} ${sgst.toFixed(2)}`, 150, yPosition);
      yPosition += 10;
    } else if (igst > 0) {
      doc.text(`IGST 18%: ${currencysymbl} ${igst.toFixed(2)}`, 150, yPosition);
      yPosition += 10;
    }

    if (rowData.discount) {
      let discountText = "Discount: ";
      if (rowData.discountType === "%") {
        discountText += `${rowData.discount}%`;
      } else {
        discountText += `${currencysymbl} ${rowData.discount}`;
      }
      doc.text(discountText, 150, yPosition);
      yPosition += 10;
    }

    let discountAmount = rowData.discountType === "%" 
      ? (subtotal * rowData.discount) / 100 
      : rowData.discount;
    let totalAfterDiscount = subtotal - discountAmount + cgst + sgst + igst;
    
    doc.text(`Total: ${currencysymbl} ${totalAfterDiscount.toFixed(2)}`, 150, yPosition);
    yPosition += 10;
    const customerNotesTitle = "Customer Notes:";
    const customerNotesContent = rowData.customernotes || "None";
    const maxWidth = 180;

    const addTextWithPagination = (
      doc: jsPDF,
      title: string,
      content: string,
      startY: number,
      showTitleOnFirstPage: boolean = true
    ) => {
      let contentYPosition = startY;
      const lines = doc.splitTextToSize(content, maxWidth);
    
      let titleDisplayed = !showTitleOnFirstPage; 
    
      for (const line of lines) {

        if (contentYPosition > doc.internal.pageSize.height - 30) {
          doc.addPage();
          contentYPosition = 10;
    

          if (showTitleOnFirstPage && !titleDisplayed) {
            doc.text(title, 20, contentYPosition);
            contentYPosition += 5;
            titleDisplayed = true;
          }
        }
    

        if (!titleDisplayed && showTitleOnFirstPage) {
          doc.text(title, 20, contentYPosition);
          contentYPosition += 5;
          titleDisplayed = true;
        }
    

        doc.text(line, 20, contentYPosition);
        contentYPosition += 10;
      }
    
      return contentYPosition;
    };
    

    yPosition = addTextWithPagination(
      doc,
      customerNotesTitle,
      customerNotesContent,
      yPosition,
      true 
    );
    
    yPosition += 10;

    const termsTitle = "Terms and Conditions:";
    const termsContent = rowData.terms_conditions || "None";
    addTextWithPagination(doc, termsTitle, termsContent, yPosition, true);

    doc.save(`${rowData.invoiceid}.pdf`);
  };

  const [userType, setUserType] = useState<number | null>(null);
  useEffect(() => {
    const storedUserType = localStorage.getItem("usertype");
    if (storedUserType !== null) {
      const parsedUserType = parseInt(storedUserType, 10);
      setUserType(parsedUserType);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewClick = (row: any) => {
    setOpen(true);
    setViewData(row);
    const customerData = customerOptions.find(
      (customer) => customer.id === row.customer_id
    );
    setCutomerData(customerData);
  };
  const columns = [
    { field: "serial_no", headerName: "S.No", width: 150 },
    { field: "invoiceid", headerName: "Invoice ID", width: 175 },
    { field: "invoicedate", headerName: "Invoice Date", width: 150 },
    {
      field: "price",
      headerName: "Invoice Amount",
      width: 175,
      renderCell: (params: any) => {
        const currencySymbol = getCurrencySymbol1(params.row.currency);
        return params.value != null
          ? `${currencySymbol}  ${params.value}`
          : "NA";
      },
    },
    {
      field: "due_amount",
      headerName: "Balance Amount",
      width: 150,
      renderCell: (params: any) => {
        const currencySymbol = getCurrencySymbol1(params.row.currency);
        return params.value !== null && !isNaN(params.value)
          ? `${currencySymbol} ${params.value}`
          : "NA";
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 175,
      renderCell: (params: { value: string }) => {
        const status = params.value;
        const { color, Icon } = getStatusColorAndIcon(status);

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon style={{ color, marginRight: "5px" }} />
            {status}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params: { row: Invoicemock }) => (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "50px",
            cursor: "pointer",
          }}
        >
          <IconButton onClick={() => handleViewClick(params.row)}>
            <VisibilityIcon
              style={{
                width: "24px",
                height: "26px",
                color: "181D8C",
                marginTop: "7px",
                cursor: "pointer",
              }}
            />
          </IconButton>
          <SaveAltIcon
            style={{ color: "#165D59" }}
            onClick={() => handleDownloadPDF(params.row)}
          />
        </div>
      ),
      width: 100,
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        minHeight: "84vh",
        padding: "20px",
        maxWidth: isSidebarOpen ? "calc(100% - 70px)" : "calc(100% - 80px)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{
              fontSize: "18px",
              fontFamily: "Roboto",
              color: "#181D8C",
              fontWeight: 600,
              textAlign: "center",
              lineHeight: "21px",
            }}
          >
            {TEXT_MESSAGES.INVOICE}
          </Typography>
          <PheonixBreadcrumbs />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          border: "0.5px solid #B7BAFF",
        }}
      >
        <PheonixDataGrid
          rows={
            filteredInvoicesData.length > 0
              ? filteredInvoicesData
              : invoicessData
          }
          columns={columns}
          pageSizeOptions={[5, 10, 20, 30]}
          rowCount={totalPages}
          style={{ border: "0px", marginLeft: "20px" }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          onSearchQueryChange={handleSearchQueryChange}
          searchQuery={searchQuery}
          loading={dataLoading}
        />
      </div>
      <PheonixSnackBar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={succMessage}
        vertical="top"
        horizontal="center"
      />
      <InvoiceModal
        open={open}
        viewData={viewData}
        onClose={handleClose}
        customerData={customerData}
      />
      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={confirmDelete}
        entity={entityToDisplay}
        entityname="Invoice"
      />
    </div>
  );
};
export default Invoicescreen;
