import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../Components/PheonixDeleteModal";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import { useNavigate } from "react-router-dom";
import { api } from "../../api";
import axios from "axios";
import Quote from "./Quote";
import Popover from "@mui/material/Popover";
import PheonixButton from "../../Components/PheonixButton";
import { Typography, Box, IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSignUpContext } from "../../Contexts/SignUpContext";
import PheonixDataGrid from "../../Components/PheonixDataGrid";
import PheonixModal from "./PheonixModal";
import { TEXT_MESSAGES } from "../../const";
import { DELETE_QUOTATION } from "../../graphql/mutation";
import PheonixBreadcrumbs from "../../Components/PheonixBreadcrumbs";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import PheonixSnackBar from "../../Components/PheonixSnackBar";
import { GET_ALL_CUSTOMERS } from "../../graphql/query";

interface CustomerOption {
  id: string;
  name?: string;
  email: string;
  number?: string;
  gst_no?: string;
  address?: string;
  customerNo: string;
  city?: string;
  state?: string;
  pincode?: string;
  country?: string;
}
const customer: CustomerOption = {
  id: "",
  name: "",
  email: "",
  number: "",
  gst_no: "",
  address: "",
  customerNo: "",
  city: "",
  state: "",
  pincode: "",
  country: "",
};

const QuotesInPaper: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useSignUpContext();
  const [open, setOpen] = React.useState(false);
  const [quotationsData, setQuotationsData] = useState<Quote[]>([]);
  const [filteredQuotationsData, setFilteredQuotationsData] = useState<Quote[]>(
    []
  );
  const [error, setError] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedQuote, setSelectedQuote] = useState<Quote | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quote, setQuote] = useState<Quote | null>(null);
  const [serialNumberCounter, setSerialNumberCounter] = useState(1);
  const [isQuotationOpen, setIsQuotationOpen] = useState(false);
  const [editid, setEditId] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [containerEl, setContainerEl] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);
  const [entityToDisplay, setEntityToDisplay] = useState("");
  const [succMessage, setSuccMessage] = useState("");
  const [view, setview] = useState(true);
  const [dataLoading, setdataLoading] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([
    customer,
  ]);
  const [selectedCustomer, setselectedCustomer] =
    useState<CustomerOption>(customer);
  const [breadcrumbText, setBreadcrumbText] = useState<string>("");
  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);
  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: GET_ALL_CUSTOMERS,
        },
        config
      );
      const customers = response.data.data.getAllUsers;
      const customersClient = customers.filter(
        (customer: any) => customer.user_type === "2"
      );
      if (customers) {
        const customerOptions = customersClient.map(
          (customer: {
            _id: string;
            customer_no: string;
            basic_info: {
              firstname: string;
              email: string;
              phone_number: string;
              shipping_address: {
                address_line1: string;
                city: string;
                state: string;
                pincode: string;
                country: string;
              };
            };
            billing_info: { GST_no: string };
          }) => ({
            id: customer._id,
            name: customer.basic_info.firstname,
            email: customer.basic_info.email,
            number: customer.basic_info.phone_number,
            gst_no: customer.billing_info.GST_no,
            address: customer.basic_info.shipping_address.address_line1,
            customerNo: customer.customer_no,
            city: customer.basic_info.shipping_address.city,
            state: customer.basic_info.shipping_address.state,
            pincode: customer.basic_info.shipping_address.pincode,
            country: customer.basic_info.shipping_address.country,
          })
        );
        setCustomerOptions(customerOptions);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  const open1 = Boolean(containerEl);
  const id1 = open1 ? "simple-popover" : undefined;
  useEffect(() => {
    fetchQuotationData();
  }, [paginationModel.page, paginationModel.pageSize, searchQuery]);

  const handleDeleteClick = (id: string, name: string) => {
    setEntityToDelete(id);
    setEntityToDisplay(name);
    setDeleteModalOpen(true);
  };
  const confirmDelete = async () => {
    if (!entityToDelete) return;
    const editQuote = quotationsData.find(
      (obj) => obj.id.toString() === entityToDelete.toString()
    );
    const token = localStorage.getItem("token");
    const query = DELETE_QUOTATION;
    const variables = {
      id: entityToDelete,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        config
      );
      const { success, message } = response.data.data.deleteQuotation;
      if (success) {
        setSuccMessage(TEXT_MESSAGES.QUOTE_DELETE_SUCCESS);
        setSuccMessage(
          `${editQuote?.quotationid} - ${TEXT_MESSAGES.QUOTE_DELETE_SUCCESS}`
        );
        setSnackbarSeverity("error");
        setDeleteModalOpen(false);
      } else {
        setSuccMessage(TEXT_MESSAGES.QUOTE_DELETE_FAIL);
        setSnackbarSeverity("error");
      }
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
        fetchQuotationData();
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchQuotationData = async () => {
    try {
      setdataLoading(true);
      const userId = localStorage.getItem("userId");
      const usertype = localStorage.getItem("usertype");
      const token = localStorage.getItem("token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      if (usertype === "1") {
        const response = await axios.post(
          api.baseUrl,
          {
            query: `
            query GetQuotationbyUser( $page: Int!, $perPage: Int!, $searchQuery: String) {
              getQuotationbyUser(page: $page, perPage: $perPage, searchQuery: $searchQuery) {
                totalPages
                totalCount
                quotations {
                  id
                  serial_no
                  user_id
                  name
                  quotationid
                  quotationdate
                  productOrService {
                     name
                hsnCode
                productplan
                billcycle
                tax
                amount
                rate
                quantity
                    }
                  duedate
                  quotationnotes
                  customernotes
                  customerName
                  customerId
                  customerNo
                  phoneNumber
                  email
                  terms_conditions
                  currency
                  address
                  price
                 discount
                 discountType
                  taxamount
                  status
                }
              }
            }
          `,
            variables: {
              userId: userId,
              page: paginationModel.page,
              perPage: paginationModel.pageSize,
              searchQuery: searchQuery,
            },
          },
          config
        );
        const responseDataWithIndex =
          response.data.data.getQuotationbyUser.quotations.map(
            (item: {}, index: number) => ({
              ...item,
              serial_no: (index + 1).toString(),
            })
          );

        setQuotationsData(responseDataWithIndex);
        setTotalPages(response.data.data.getQuotationbyUser.totalCount);
      } else {
        const response = await axios.post(
          api.baseUrl,
          {
            query: `
            query GetQuotationbyUserType($customerId: ID!, $page: Int!, $perPage: Int!, $searchQuery: String) {
              getQuotationbyUserType(customerId: $customerId, page: $page, perPage: $perPage, searchQuery: $searchQuery) {
                totalPages
                totalCount
                quotations {
                  id
                  serial_no
                  user_id
                  name
                  quotationid
                  quotationdate
                  productOrService {
                     name
                hsnCode
                productplan
                billcycle
                tax
                amount
                rate
                quantity
                    }
                  duedate
                  quotationnotes
                  customernotes
                  customerName
                  customerNo
                  customerId
                  phoneNumber
                  email
                  terms_conditions
                  currency
                  address
                  price
                   discount
                   discountType
                  taxamount
                  status
                }
              }
            }
          `,
            variables: {
              customerId: userId,
              page: paginationModel.page,
              perPage: paginationModel.pageSize,
              searchQuery: searchQuery,
            },
          },
          config
        );
        const responseDataWithIndex =
          response.data.data.getQuotationbyUserType.quotations
            .filter((item: any) => item.status !== "Draft")
            .map((item: any, index: number) => ({
              ...item,
              serial_no: (index + 1).toString(),
            }));

        setQuotationsData(responseDataWithIndex);
        setTotalPages(response.data.data.getQuotationbyUserType.totalCount);
        setdataLoading(false);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleRejectQuotation = async (quotation: Quote) => {
    try {
      const quotationId = quotation.id;
      const token = localStorage.getItem("token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        api.baseUrl,
        {
          query: `
                  mutation RejectQuotation($quotationId: ID!) {
                      rejectQuotation(_id: $quotationId) {
                          id
                          serial_no
                          user_id
                          name
                          quotationid
                          quotationdate
                          productOrService {
                    name
                hsnCode
                productplan
                billcycle
                tax
                amount
                rate
                quantity
                  }
                          duedate
                          description
                          subtotal
                          total
                          address
                          price
                          status
                      }
                  }
              `,
          variables: {
            quotationId: quotationId,
          },
        },
        config
      );
      setIsModalOpen(false);
      fetchQuotationData();
    } catch (error) {
      console.error(TEXT_MESSAGES.ERR_REJECT, error);
    }
  };
  const handleAccept = async (quotation: Quote) => {
    try {
      const quotationId = quotation.id;
      const token = localStorage.getItem("token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        api.baseUrl,
        {
          query: `
                  mutation acceptQuotation($quotationId: ID!) {
                      acceptQuotation(_id: $quotationId) {
                          id
                          serial_no
                          user_id
                          name
                          quotationid
                          quotationdate
                          productOrService {
                     name
                hsnCode
                productplan
                billcycle
                tax
                amount
                rate
                quantity
                  }
                          duedate                       
                          address
                          price
                          status
                      }
                  }
              `,
          variables: {
            quotationId: quotationId,
          },
        },
        config
      );
      fetchQuotationData();
      setIsModalOpen(false);
    } catch (error) {
      console.error(TEXT_MESSAGES.ERR_ACCEPT, error);
    }
  };
  const getStatusColorAndIcon = (status: string) => {
    switch (status.toLowerCase()) {
      case "rejected":
        return { color: "#FD3232", Icon: CancelIcon };
      case "accepted":
        return { color: "#05CD31", Icon: CheckCircleIcon };
      case "active":
        return { color: "#05CD99", Icon: CheckCircleIcon };
      case "expired":
        return { color: "#AAAAAA", Icon: ErrorOutlineOutlinedIcon };
      case "draft":
        return { color: "#181D8C", Icon: CheckCircleIcon };
      default:
        return { color: "#000000", Icon: CheckCircleIcon };
    }
  };

  const handleOpenModal = (quotation: Quote) => {
    const userId = localStorage.getItem("userId");
    setSelectedQuote(quotation);
    const selectedCustomerdata = customerOptions.find(
      (customer) => customer.id === userId
    );
    if (selectedCustomerdata) {
      setselectedCustomer(selectedCustomerdata);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setBreadcrumbText("Create quotation");
    navigate("/quotationnew", {
      state: { breadcrumbText: "Create quotation" },
    });
  };
  const handleOpenCustomer = () => {
    setBreadcrumbText("Request a Quotation");
    navigate("/quotationRequest", {
      state: { breadcrumbText: "Request a Quotation" },
    });
  };
  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
  };
  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };

  const columns = [
    { field: "serial_no", headerName: "S.No", width: 150 },
    {
      field: "quotationid",
      headerName: "Quotation ID",
      width: 250,
    },
    { field: "quotationdate", headerName: "Quotation Date", width: 150 },
    {
      field: "price",
      headerName: "Amount",
      width: 160,
      renderCell: (params: any) => {
        return params.value ? `₹ ${params.value}` : "NA";
      },
    },

    { field: "duedate", headerName: "Valid Till", width: 230 },
    {
      field: "status",
      headerName: "Status",
      width: 175,
      renderCell: (params: { value: string }) => {
        const status = params.value;
        const { color, Icon } = getStatusColorAndIcon(status);

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon style={{ color, marginRight: "5px" }} />
            {status}
          </div>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => handleViewClick(params.row)}>
            <VisibilityIcon
              style={{
                width: "24px",
                height: "26px",
                color: "181D8C",
                marginTop: "7px",
                cursor: "pointer",
              }}
            />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleViewClick = (row: Quote) => {
    setview(true);
    setBreadcrumbText("View Quotation");
    if (loggedInUser === "1") {
      const editQuote = quotationsData.find(
        (obj) => obj.id.toString() === row.id.toString()
      );
      navigate("/quotationnew", {
        state: { editQuote, view, breadcrumbText: "View quotation" },
      });
    } else {
      handleOpenModal(row);
      setIsModalOpen(true);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        minHeight: "84vh",
        padding: "20px",
        maxWidth: isSidebarOpen ? "calc(100% - 70px)" : "calc(100% - 80px)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{
              fontSize: "18px",
              fontFamily: "Roboto",
              color: "#181D8C",
              fontWeight: 600,
              textAlign: "center",
              lineHeight: "21px",
            }}
          >
            {TEXT_MESSAGES.QUOTATION}
          </Typography>
          <PheonixBreadcrumbs breadcrumbText={breadcrumbText} />
        </div>
        <div style={{ marginRight: "-10px" }}>
          {loggedInUser === "1" ? (
            <PheonixButton
              style={{
                marginRight: "10px",
                color: "white",
                border: "1px solid #1C1B1F",
                backgroundColor: "#181D8C",
              }}
              onClick={handleOpen}
              label={TEXT_MESSAGES.NEW_QUOTATION}
            ></PheonixButton>
          ) : (
            <PheonixButton
              style={{
                marginRight: "10px",
                color: "white",
                border: "1px solid #1C1B1F",
                backgroundColor: "#181D8C",
              }}
              onClick={handleOpenCustomer}
              label="+ REQUEST A QUOTATION"
            ></PheonixButton>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          border: "0.5px solid #B7BAFF",
        }}
      >
        <PheonixDataGrid
          rows={
            filteredQuotationsData.length > 0
              ? filteredQuotationsData
              : quotationsData
          }
          columns={columns}
          pageSizeOptions={[5, 10, 20, 30]}
          rowCount={totalPages}
          style={{ border: "0px", marginLeft: "20px" }}
          paginationMode="server"
          paginationModel={paginationModel}
          searchQuery={searchQuery}
          loading={dataLoading}
          onPaginationModelChange={handlePaginationChange}
          onSearchQueryChange={handleSearchQueryChange}
        />
      </div>
      {isModalOpen && selectedQuote && (
        <PheonixModal
          open={isModalOpen}
          quote={selectedQuote}
          customerdata={selectedCustomer}
          onClose={handleCloseModal}
          handleAccept={handleAccept}
          rejectquote={handleRejectQuotation}
        />
      )}
      <PheonixSnackBar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        severity={snackbarSeverity}
        message={succMessage}
        vertical="top"
        horizontal="right"
      />
      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={confirmDelete}
        entity={entityToDisplay}
        entityname="Quotation"
      />
    </div>
  );
};

export default QuotesInPaper;
