import React, { ChangeEvent, useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PheonixTextField from "../../Components/PheonixTextField";
import PheonixButton from "../../Components/PheonixButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PheonixPaper from "../../Components/PheonixPaper";
import Box from "@mui/material/Box";
import PheonixBox from "../../Components/PheonixBox";
import SnackBar from "../../Components/PheonixSnackBar";
import Typography from "@mui/material/Typography";
import { Container, styled } from "@mui/material";
import PheonixContainer from "../../Components/PheonixContainer";
import { Stack } from "@mui/material";
import { api } from "../../api";
import axios from "axios";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../../const";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../assets/logo.jpg";
import { useSignUpContext } from "../../Contexts/SignUpContext";
import { useSession } from "../../Contexts/SessionContext";

const Item = styled("div")({
  marginBottom: "15px",
  padding: "8px",
  textAlign: "center",
});
const Login: React.FC = () => {
  const { isLoggedIn, login } = useSession();
  const [email, setemail] = useState(localStorage.getItem("email") || "");
  const [password, setpassword] = useState(
    localStorage.getItem("password") || ""
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [accessMessage, setAccessMessage] = useState(false);
  const [pasErrorMessage, setPasErrorMessage] = useState("");
  const [succMessage, setSuccMessage] = useState("");
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const { showSignButton } = useSignUpContext();
  const { id, setUserId } = useSignUpContext();
  const { emailId, setEmailId } = useSignUpContext();
  const [isHovered, setIsHovered] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleemailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setemail(e.target.value);
  };
  const handlepasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setpassword(e.target.value);
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleEmailFocus = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    localStorage.removeItem("email");
    const emailValid = isValidEmail(email);
    const passwordValid = isValidPassword(password);
    if (!emailValid || !password) {
      setIsLoginButtonDisabled(false);
    } else {
      setIsLoginButtonDisabled(true);
    }
  }, [email, password]);
  const isValidPassword = (password: string): boolean => {
    const minLength = 8;
    return password.length >= minLength;
  };

  const handleEmailBlur = () => {
    const emailRegex = TEXT_MESSAGES.EMAIL_REG;
    if (!emailRegex.test(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
    } else {
      setErrorMessage("");
    }
  };
  const handlePasswordBlur = () => {
    const minLength = 8;
    const passlength = password.length >= minLength;
    if (password.length === 0) {
      setPasErrorMessage(ERROR_MESSAGES.VALID_PASSWORD);
    } else if (!passlength) {
      setPasErrorMessage(ERROR_MESSAGES.PWD_LENGTH);
    } else {
      setPasErrorMessage("");
    }
  };
  const handlePasswordFocus = () => {
    setPasErrorMessage("");
  };
  function isValidEmail(email: string) {
    const emailRegex = TEXT_MESSAGES.EMAIL_REG;
    return emailRegex.test(email);
  }

  const handleLogin = async () => {
    if (!isValidEmail(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
      return;
    }
    const query = `
    mutation LoginUser($email : String!,  $password: String!){
        loginUser(email : $email,  password : $password){
        success
        message 
        userId
        user_type
        token
        }
    }
    `;
    const variables = {
      email: email,
      password: password,
    };
    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });
      const { success, message, userId, user_type, token } =
        response.data.data.loginUser;
      if (success) {
        localStorage.setItem("userId", userId);
        localStorage.setItem("usertype", user_type);
        localStorage.setItem("token", token);
        setLoggedInUser(user_type);
        setEmailId(email);
        setSnackbarSeverity("success");
        setSuccMessage(TEXT_MESSAGES.Log_SUCCESS);

        setModalOpen(true);

        setTimeout(() => {
          if (user_type === "1") {
            navigate("/home");
          } else {
            navigate("/dashboard");
          }

          setemail("");
          setpassword("");
        }, 3000);
        login();
      } else {
        const error = message.toLowerCase();
        if (error.includes("email")) {
          setErrorMessage(message);
        }
        if (error.includes("access")) {
          setAccessMessage(true);
          setTimeout(() => {
            setAccessMessage(false);
          }, 3000);
        } else if (error.includes("password")) {
          setPasErrorMessage(message);
        } else {
          setSuccMessage(message);
          setSnackbarSeverity("success");
          setModalOpen(true);
          setTimeout(() => {
            setModalOpen(false);
          }, 3000);
        }
      }
    } catch (error) {
      console.error(ERROR_MESSAGES.ERROR, error);
    }
  };
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    if (loggedInUser === "1") {
      navigate("/home");
    } else {
      navigate("/dashboard");
    }
  };
  const customCloseIcon = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
      style={{ justifyContent: "end" }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  return (
    <div style={{ marginTop: "20px" }}>
      <PheonixBox
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <PheonixContainer
          maxWidth="xs"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <PheonixPaper
            elevation={3}
            style={{
              padding: "20px",
              height: accessMessage ? "700px" : "550px",
              marginLeft: "10px",
              width: "400PX",
              borderRadius: "0",
            }}
          >
            <Stack spacing={1} direction="column">
              <Item>
                <img
                  src={logo}
                  alt="Your Alt Text"
                  style={{ width: "150px", height: "99px" }}
                />
              </Item>

              <Box component="form" onKeyPress={handleKeyPress}>
                <Item>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#181D8C",
                      fontFamily: "Roboto",
                      marginTop: "-20px",
                    }}
                  >
                    {TEXT_MESSAGES.LOG_TEXT}
                  </Typography>
                </Item>
                <Item>
                  <PheonixTextField
                    id="outlined-error"
                    type="email"
                    value={email}
                    placeholder="Email"
                    label="Email"
                    variant="outlined"
                    onChange={handleemailChange}
                    onFocus={handleEmailFocus}
                    onBlur={handleEmailBlur}
                    error={Boolean(errorMessage)}
                    helperText={errorMessage || ""}
                    style={{ width: "350px" }}
                  />
                </Item>
                <Item>
                  <PheonixTextField
                    id="outlined-error"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    placeholder="Password"
                    label="Password"
                    variant="outlined"
                    onChange={handlepasswordChange}
                    onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur}
                    error={Boolean(pasErrorMessage)}
                    helperText={pasErrorMessage || ""}
                    style={{ width: "350px" }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          marginTop: "0px",
                          color: "black",
                        }}
                        onClick={handleTogglePassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </InputAdornment>
                    }
                    disabled={Boolean(errorMessage)}
                  />
                </Item>
                <Item style={{ justifyContent: "flex-end", textAlign: "end" }}>
                  <a
                    href="/requestotp"
                    style={{
                      marginRight: "15px",
                      fontSize: "16px",
                      color: isHovered ? "#181D8C" : "#2196F3",
                      textDecoration: isHovered ? "underline" : "none",
                      marginTop: "-8px",
                      transition: "color 0.3s",
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => {
                      sessionStorage.setItem("cameFromLogin", "true");
                      setErrorMessage("");
                      setPasErrorMessage("");
                    }}
                  >
                    {TEXT_MESSAGES.FORGOT_TEXT}
                  </a>
                </Item>
                <Item>
                  <PheonixButton
                    label={TEXT_MESSAGES.LOG_BUTTON}
                    onClick={handleLogin}
                    disabled={!isLoginButtonDisabled}
                  />
                </Item>
                <Item>
                  {!showSignButton && (
                    <PheonixButton
                      label={TEXT_MESSAGES.SIGN_TEXT}
                      onClick={() => {
                        sessionStorage.setItem("cameFromLogin", "true");
                        window.location.href = "/signup";
                      }}
                      disabled={showSignButton}
                    />
                  )}
                </Item>
                {accessMessage && (
                  <div
                    style={{
                      justifyContent: "center",
                      padding: "10px",
                      borderRadius: "4px",
                      maxWidth: "350px",
                      margin: "0 auto",
                    }}
                  >
                    <Typography
                      style={{
                        color: "var(--notification-bell-red, #DB1B1B)",
                        marginLeft: "35px",
                        backgroundColor: "rgba(219, 27, 27, 0.2)",
                        fontFamily: "Roboto",
                        height: "30px",
                        width: "280px",
                        display: "flex",
                        fontWeight: 700,
                        fontSize: "14px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {TEXT_MESSAGES.ACCESS_DENIED}
                    </Typography>

                    <Typography
                      style={{
                        color: "black",
                        marginTop: "10px",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {TEXT_MESSAGES.SUPPORT}
                    </Typography>
                  </div>
                )}
              </Box>
            </Stack>
          </PheonixPaper>
        </PheonixContainer>
        <SnackBar
          open={modalOpen}
          onClose={handleClose}
          message={succMessage}
          severity={snackbarSeverity}
          vertical="top"
          horizontal="center"
          customAction={customCloseIcon}
        />
      </PheonixBox>
    </div>
  );
};
export default Login;
